setResponsive({
    mobile: 540,
    tablet: 1024,
    desktop: Infinity
});

function pushGTMEventWithCallback(maxTimeout: number, args: { event: string, ecommerce?: object, eventCallback?: (containerId?: string) => void }) {
    let callbackExecuted = false;
    dataLayer.push({
        ...args,
        ...(args.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        args.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (args.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                args.eventCallback();
            }
        }, maxTimeout);
    }
}

$(function () {
    if (exists("#home-container")) {
        homePage();
    }
    else if (exists("#cart-container")) {
        cartPage();
    }
    else if (exists("#catalog-container")) {
        catalogPage();
    }
    else if (exists("#checkout-container")) {
        checkoutPage();
    }
    else if (exists("#product-container")) {
        productPage();
    }
    else if (exists("#profile-container")) {
        profilePage();
    }
    else if (exists("#wishlist-container")) {
        wishlistPage();
    }
    else if (exists('#subscription-container')) {
        subscriptionPage();
    }
    else if (exists('#subscriptions-container')) {
        subscriptionPage();
    }
    else if (exists('#password-recovery-container')) {
        passwordRecoveryPage();
    }
    else if (exists('.faq-section')) {
        faqPage();
    }
    else if (exists('.tools-export')) {
        toolsExport();
    }

    if (exists(".subscribe-container")) {
        subscribeComponent();
    }
    if (exists(".product-container")) {
        productComponent();
    }
    if (exists("header")) {
        headerComponent();
    }
    if (exists("#menu")) {
        menuComponent();
    }
    if (exists(".subscribe-newsletter")) {
        newsletterComponent();
    }
    if (exists("#contact-form")) {
        contactComponent();
    }
    if (exists(".swiper-container")) {
        swiperComponent();
    }
    if (exists('dialog')) {
        initDialogs();
    }
});


// Other scripts to be called on every page
$(function () {
    initBackgroundImageLazyLoad();
    initInputMasks();
    initForceUpperCase();
    initToggle();
    initModals();
    initTabs();
    initSortableTables();
    initSearch();
    initEventEmitters();
    initMultiStep();
    initLightbox();
    initTelDropdowns();
    
    $('a[href^="#"]').on('click', function (event) {
        event.preventDefault();

        const hash = $(this).attr('href');
        scrollToElement(hash).then(() => window.location.hash = hash);
    });

    $('#country-selector .country-list ul > li:not(.link)').withData('country').on('click', function() {
        const country = $(this).data('country');
        createCookie('country', country);
        $(this).trigger('modal:close');
    });

    $('#country-selector .country-list ul > li.link').withData('country').on('click', function() {
        window.location.href = $(this).find('a').attr('href');
    });

    $(document)
        .on('set-currency', function(event, currency) {
            event.preventDefault();
            const currentCurrency = checkCookie('currency');

            if (currency != currentCurrency) {
                createCookie('currency', currency);
                window.location.reload();
            }
        })
        .on('toggle-page-scroll', function() {
            if (pageIsScrollable()) {
                blockPageScroll();
            }
            else {
                restorePageScroll();
            }
        })
        .on('addtocart:success', function() {
            replacePageElements(['#cart-icon', '#cart-icon-mobile']);
        })
        .on('wishlist:add wishlist:remove', function() {
            replacePageElements(['#wishlist-icon', '#wishlist-icon-mobile']);
        });

    if (exists('.faq-section') && exists(window.location.hash)) {
        openFaqAnswer(window.location.hash);
        $(window).on('popstate', () => openFaqAnswer(window.location.hash));
    }

    if (exists('.alert')) {
        setTimeout(() => $('.alert-box').hide(), messageBoxDuration);
    }

    if (exists('[name*="country_id"]:not([type="hidden"])') && exists('[name*="prov"]')) {
        $<HTMLSelectElement>('[name*="country_id"]:not([type="hidden"])').on('change', function() {
            const form = this.closest('form');
            if (form) {
                const prov = form.querySelector<HTMLInputElement>('[name*="prov"]');
                if (prov) {
                    const label = prov.parentElement.querySelector('label');
                    const required = this.value == '108';
                    prov.required = required;
                    label.textContent = label.textContent.trim().replace('*', '') + (required ? '*' : '');
                }
            }
        });

        $<HTMLSelectElement>('[name*="country_id"]:not([type="hidden"])').trigger('change');
    }
});